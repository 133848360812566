import Cookies from 'js-cookie';

const cookiesNames = {
  isCookiesPopup: 'COOKIES_POPUP',
  amplitudeAnalytics: 'AMPLITUDE_ANALYTICS',
  googleAnalytics: 'GOOGLE_ANALYTICS'
};

const state = {
  isCookiesDialog: false,
  isCookiesPopup: Cookies.getJSON(cookiesNames.isCookiesPopup),
  amplitudeAnalytics: Cookies.getJSON(cookiesNames.amplitudeAnalytics),
  googleAnalytics: Cookies.getJSON(cookiesNames.googleAnalytics)
};

const getters = {
  getCookiesDialog: state => state.isCookiesDialog,
  getCookiesPopup: state => {
    if (state.isCookiesPopup === undefined) {
      return true;
    }

    return state.isCookiesPopup;
  },
  isGoogleAnalyticsCookies: state =>
    state.googleAnalytics === undefined || state.googleAnalytics
};

const mutations = {
  updateCookiesDialogs(state, payload) {
    state.isCookiesDialog = payload;
  },
  updateCookiesPopup(state, payload) {
    Cookies.set(cookiesNames.isCookiesPopup, payload);
    state.isCookiesPopup = payload;
  },
  updateGoogleAnalytics(state, payload) {
    Cookies.set(cookiesNames.googleAnalytics, payload);
    state.googleAnalytics = payload;
  }
};

const actions = {
  setCookiesDialogs({ commit }, value) {
    commit('updateCookiesDialogs', value);
  },
  setCookiesPopup({ commit }, value) {
    commit('updateCookiesPopup', value);
  },
  setGoogleAnalytics({ commit }, value) {
    commit('updateGoogleAnalytics', value);
  },
  closeCookieWidget({ commit }) {
    commit('updateCookiesPopup', false);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
