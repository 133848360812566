import gql from 'graphql-tag';
import { getContextTMC } from '@/utils/tmc';

const query = gql`
  query suggest(
    $destination: String
    $latitude: Float
    $longitude: Float
    $type: String!
    $offset: Int
    $travelStarting: String
    $travelEnding: String
    $corporationId: String
    $userId: String
    $activeTripId: String
    $comingFromEmailML: Boolean
    $accountNumber: String
    $hotelIdsCurrentlyShowing: [String]
  ) {
    suggest(
      destination: $destination
      latitude: $latitude
      longitude: $longitude
      type: $type
      offset: $offset
      travelStarting: $travelStarting
      travelEnding: $travelEnding
      corporationId: $corporationId
      userId: $userId
      activeTripId: $activeTripId
      comingFromEmailMl: $comingFromEmailML
      tmc: "${getContextTMC()}"
      accountNumber: $accountNumber
      hotelIdsCurrentlyShowing: $hotelIdsCurrentlyShowing
    ) {
      currency
      currencyName
      extId
      lat
      lng
      name
      price
      rating
      source
      thumbnailUrl
      freeCancellation
      options
      chainCode
      chainName
      reviewTitle
      reviewScore
      reviewCount
    }
  }
`;

export default function suggest({
  apollo,
  destination,
  latitude,
  longitude,
  type,
  travelStarting,
  travelEnding,
  offset,
  corporationId,
  userId,
  activeTripId,
  comingFromEmailML,
  accountNumber,
  hotelIdsCurrentlyShowing
}) {
  return apollo.query({
    query,
    variables: {
      destination,
      latitude,
      longitude,
      type,
      travelStarting,
      travelEnding,
      offset,
      corporationId,
      userId,
      activeTripId,
      comingFromEmailML,
      getContextTMC,
      accountNumber,
      hotelIdsCurrentlyShowing
    }
  });
}
