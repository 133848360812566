<template>
  <v-tooltip top :open-on-click="true">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="text" icon small class="mr-2 mb-1" v-bind="attrs" v-on="on">
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </template>
    <span>
      Inclusive of taxes where provided local taxes may also be charged in
      destinations
    </span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'TaxToolTip'
};
</script>
