function getContextTMC() {
  if (process.env.VUE_APP_THEME == 'marvin-gdg-fe') {
    return 'gdg';
  }
  if (process.env.VUE_APP_THEME == 'marvin-ct-fe') {
    return 'ct';
  }
  if (process.env.VUE_APP_THEME == 'marvin-nt-fe') {
    return 'nt';
  }
  if (process.env.VUE_APP_THEME == 'marvin-bbt-fe') {
    return 'bbt';
  }
  if (process.env.VUE_APP_THEME == 'marvin-generic-fe') {
    return 'generic';
  }
  if (process.env.VUE_APP_THEME == 'marvin-ot-fe') {
    return 'ot';
  }
  if (process.env.VUE_APP_THEME == 'marvin-tc-fe') {
    return 'tc';
  }
  if (process.env.VUE_APP_THEME == 'marvin-wt-fe') {
    return 'wt';
  }
  if (process.env.VUE_APP_THEME == 'marvin-gant-fe') {
    return 'gant';
  }
}

export { getContextTMC };
