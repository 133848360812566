import gql from 'graphql-tag';
import { getContextTMC } from '@/utils/tmc';

const query = gql`
  query fetchHotel(
    $hotelExtId: String
    $suggestionId: String
    $source: SuggestionSourceTypeEnum!
  ) {
    fetchHotel(
      hotelExtId: $hotelExtId
      suggestionId: $suggestionId
      source: $source
      tmc: "${getContextTMC()}"
    ) {
      attribution
      price
      category
      currency
      currencyName
      extId
      hotelFacilities
      id
      itineraryId
      lat
      lng
      moreInfo
      jsonDetailedInfo
      detailedInfo
      name
      pictures
      rating
      shortDescription
      slug
      source
      thumbnailUrl
      popularWithColleagues
      address
      chainCode
      chainName
      reviewTitle
      reviewScore
      reviewCount
    }
  }
`;

export default function fetchHotel({
  apollo,
  hotelExtId,
  suggestionId,
  source
}) {
  return apollo.query({
    query,
    variables: {
      hotelExtId,
      suggestionId,
      source,
      getContextTMC
    }
  });
}
