const Mixpanel = require('mixpanel');
const ipify = require('ipify2');
const mixpanel = Mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
  keepAlive: false
});

export const logMixPanelEvent = async (event, options, coordinates = null) => {
  if (coordinates != null) {
    options['$latitude'] = coordinates.latitude;
    options['$longitude'] = coordinates.longitude;
  } else {
    options['ip'] = await ipify.ipv4();
  }
  mixpanel.track(event, options);
};

export const mixPanelEvent = {
  pageView: 'page view',
  chooseHotel: 'choose hotel',
  searchLocation: 'search location',
  searchLocationNoResults: 'search location no results',
  destinationSearchBar: 'destination search bar',
  breadCrumbHome: 'bread crumb home',
  mapView: 'map view',
  filtersMenu: 'filters menu',
  privacyCloseMenu: 'privacy close menu',
  privacyAdjustSettings: 'privacy adjust settings',
  privacyCookiePage: 'privacy cookie page',
  locationViewMore: 'location view more',
  hotelEvents: {
    hotelFilterEvents: {
      starRating: 'star rating',
      brands: 'hotel brands filter',
      freeCancelation: 'free cancellation filter',
      breakFast: 'break fast filter',
      distance: 'distance filter',
      resetFilter: 'reset filter',
      applyFilter: 'apply filter'
    },
    viewMoreHotels: 'view more hotels',
    hotelCardClck: 'hotel card',
    hotelDetailEvents: {
      roomFacilities: 'room facilities',
      roomRooms: 'room rooms',
      roomPhotos: 'room photos',
      roomOverview: 'room overview',
      viewMoreFaclities: 'view more facilities',
      detailsShowMore: 'hotel details show more',
      guestSelector: 'guest calculator',
      checkInOutCalender: 'check in out calender',
      verifyEmail: 'verify email',
      addCar: 'add car',
      saveCar: 'save car',
      roomsSeeRooms: 'rooms see rooms '
    },
    hotelRoomEvents: {
      roomMoreDetails: 'room more details',
      roomChoose: 'room choose'
    }
  },
  footerEvents: {
    privacyPolicy: 'privacy policy',
    cookies: 'cookies',
    termsAndCoditions: 'terms and conditions',
    AcceptableUsePolicy: 'acceptable use policy',
    twitter: 'social twitter',
    facebook: 'social facebook',
    instagram: 'social instagram',
    linkedIn: 'social linkedin'
  },
  accountConfirmationEvents: {
    accountConfirmation: 'account confirmation',
    accountConfirmationClose: 'close account confirmation'
  },
  cartOptions: {
    basketRemoved: 'basket removed',
    basketRequest: 'basket request'
  }
};
