import gql from 'graphql-tag';
import { getContextTMC } from '@/utils/tmc';

// FIXME!! Receive and send the travelManagement company received in URL
const query = gql`
  query fetchUser($uId: String!, $tripId: String!) {
    fetchUser(userId: $uId, tripId: $tripId, tmc: "${getContextTMC()}") {
      firstName
      lastName
      email
      userId
      userStatus
    }
    tmcByTripId(tripId: $tripId)
  }
`;

// uId should be a dictionary with uId and tripId as keys e.g: {uId: "123", tripId: "234"}
export default function fetchUser({ apollo, uId }) {
  return apollo.query({
    query,
    variables: uId
  });
}
