import gql from 'graphql-tag';
import { getContextTMC } from '@/utils/tmc';

const query = gql`
  query minimumHotelsPrices(
    $hotelIds: [String!]!
    $checkin: String!
    $checkout: String!
    $source: SuggestionSourceTypeEnum!
    $corporationId: String
    $accountNumber: String
  ) {
    minimumHotelsPrices(
      hotelIds: $hotelIds
      checkin: $checkin
      checkout: $checkout
      source: $source
      tmc: "${getContextTMC()}"
      corporationId: $corporationId
      accountNumber: $accountNumber
    ) {
      hotelMinimumRates {
        currency
        currencyName
        hotelId
        minimumRate
        badPrice
      }
    }
  }
`;

export default function minimumHotelsPrices({
  apollo,
  hotelIds,
  checkin,
  checkout,
  source,
  corporationId,
  accountNumber
}) {
  return apollo.query({
    query,
    variables: {
      hotelIds,
      checkin,
      checkout,
      source,
      corporationId,
      accountNumber
    }
  });
}
