import apolloClient from '../../vue-apollo';
import suggest from '../../queries/suggest';
import minimumHotelsPrices from '../../queries/minimumHotelsPrices';
import singleHotelLabeler from '../../queries/fetchHotelLabel';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _isEmpty from 'lodash/isEmpty';
import { getLengthKm } from '@/utils/mapUtils';
import { logMixPanelEvent, mixPanelEvent } from '../../utils/mixpanel';
import { getContextTMC } from '@/utils/tmc';

const state = {
  items: [],
  page: 1,
  showItemsPerPage: 9,
  endOfResults: false,
  loading: false,
  loadingMore: false,
  filtered: false,
  hotelRoomPrices: {},
  lastPositionNumber: 0,
  hotelIdsCurrentlyShowing: [],
  allResultsFetched: false,
  hotelRoomBadPrices: {}
};

const getters = {
  getAllItems: state => state.items,
  getItems: (state, getters, rootState, rootGetters) => {
    const filtered = state.items.filter(card => {
      const filters = rootGetters['filter/getMoreFilter'];
      let cardOptions = {};
      try {
        cardOptions = card.options ? JSON.parse(card.options) : {};
      } catch (error) {
        cardOptions = {};
      }
      let byStar = true;
      let byBrands = true;
      let byPrice = true;
      let byCancellation = true;
      let byBreakfast = true;
      let byDistance = true;

      if (filters.starRating.length) {
        byStar = filters.starRating.includes(card.rating);
      }

      if (filters.brands.length) {
        byBrands = filters.brands.includes(card.chainCode.toString());
      }

      /**
       * Price Filters
       * Given when minimum and Maximum or Any of those Prices are Filled
       */
      if (!_isEmpty(filters.minPrice) || !_isEmpty(filters.maxPrice)) {
        if (!_isEmpty(filters.minPrice) && !_isEmpty(filters.maxPrice)) {
          byPrice =
            card.price >= filters.minPrice && card.price <= filters.maxPrice;
        }
        if (!_isEmpty(filters.minPrice) && _isEmpty(filters.maxPrice)) {
          byPrice = card.price >= filters.minPrice;
        }
        if (_isEmpty(filters.minPrice) && !_isEmpty(filters.maxPrice)) {
          byPrice = card.price <= filters.maxPrice;
        }
      }

      if (filters.freeCancellation) {
        byCancellation = filters.freeCancellation === card.freeCancellation;
      }

      if (filters.breakfastIncluded) {
        byBreakfast = filters.breakfastIncluded === cardOptions.freeBreakfast;
      }

      if (filters.distanceFromCenter) {
        const cityCenter = rootGetters['filter/getSearchQueryOptions'];

        const lengthKm = getLengthKm(
          [card.lng, card.lat],
          [cityCenter.longitude, cityCenter.latitude]
        );

        byDistance = lengthKm < filters.distanceFromCenter;
      }

      return (
        byStar &&
        byBrands &&
        byPrice &&
        byCancellation &&
        byBreakfast &&
        byDistance
      );
    });

    return _sortBy(filtered, [
      item => {
        let sortByPriceExist = false;

        const price = getters.getHotelRoomPrices[item.extId];

        if (price && price.loading === false && price.price === 'N/A') {
          sortByPriceExist = true;
        }

        return sortByPriceExist;
      }
    ]);
  },
  getEndOfResults: (state, getters) => getters.endOfResults,
  getPage: state => state.page,
  getShowItemsLength: state => state.showItemsPerPage * state.page,
  getItemsToShow: (state, getters) =>
    getters.getItems.slice(0, getters.getShowItemsLength),
  getLoading: state => state.loading,
  getLoadingMore: state => state.loadingMore,
  getFiltered: state => state.filtered,
  getHotelRoomPrices: state => state.hotelRoomPrices,
  getHotelRoomPricesArray: state => Object.values(state.hotelRoomPrices),
  getLastPositionNumber: state => state.lastPositionNumber,
  getHotelIdsCurrentlyShowing: state => state.hotelIdsCurrentlyShowing,
  getAllResultsFetched: state => state.allResultsFetched,
  getHotelRoomBadPrices: state => state.hotelRoomBadPrices
};

const mutations = {
  setItems(state, payload) {
    state.items = payload;
    state.endOfResults = false;
  },
  increaseItems(state, payload) {
    const preLength = state.items.length;
    state.items = state.items.concat(payload);
    state.endOfResults = preLength === state.items.length;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setLoadingMore(state, value) {
    state.loadingMore = value;
  },
  setFiltered(state, value) {
    state.filtered = value;
  },
  increasePage(state) {
    state.page = state.page + 1;
  },
  resetPage(state) {
    state.page = 1;
  },
  updateHotelRoomPrices(state, { id, value }) {
    state.hotelRoomPrices = {
      ...state.hotelRoomPrices,
      [id]: value
    };

    // Update the price of each Hotel in Hotel listing if price is fetched.
    state.items.map(card => {
      if (card.extId === id) {
        card.price = value.price;
        card.badPrice = value.badPrice;
      }
    });
  },

  updateHotelRoomLabel(state, { hotel, response }) {
    if (response) {
      let labelToDisplay = undefined;
      let labelToDisplayBasedInOrder = undefined;
      Object.keys(response).forEach(labelName => {
        const label = response[labelName];
        if (label.label_text.length) {
          if (labelToDisplayBasedInOrder == undefined) { 
            labelToDisplayBasedInOrder = label; 
          } else {
            if (label.apply_order < labelToDisplayBasedInOrder.apply_order) {
              labelToDisplayBasedInOrder = label;
            }
          }

          if (labelToDisplayBasedInOrder != undefined) {
            labelToDisplay = labelToDisplayBasedInOrder;
          }
        }
      });
      let includeLoyaltyPointsLbl = false;
      ['hilton', 'hyatt', 'ihg', 'holiday inn', 'marriott', 'accor'].forEach(chain => {
        if (hotel.chainName.toLowerCase().includes(chain)) {
          includeLoyaltyPointsLbl = true;
          return;
        }
      })
      if (labelToDisplay && labelToDisplay.label_text && labelToDisplay.label_text.toLowerCase() === 'earn loyalty points' && !includeLoyaltyPointsLbl) {
        labelToDisplay.label_text = '';
      }

      if (labelToDisplay && labelToDisplay.label_text) {
        hotel.label = labelToDisplay.label_text;
      } else {
        hotel.label = '';
      }
    }
  },
  updateHotelRoomBadPrices(state, { id, value }) {
    state.hotelRoomBadPrices = {
      ...state.hotelRoomBadPrices,
      [id]: {
        ...state.hotelRoomBadPrices[id], // Preserve other properties for the specified 'id'
        badPrice: value.badPrice // Update the 'badPrice' value
      }
    };
  },

  updateLastPostionNumber(state, hotelCount) {
    state.lastPositionNumber = hotelCount;
  },
  setHotelIdsCurrentlyShowing(state, hotelIds) {
    if (hotelIds.length > 0) state.hotelIdsCurrentlyShowing.push(...hotelIds);
  },
  resetHotelIdsCurrentlyShowing(state) {
    state.hotelIdsCurrentlyShowing = [];
  },
  setAllResultsFetched(state, value) {
    state.allResultsFetched = value;
  }
};

const actions = {
  async getCardsContent({ commit, getters, rootGetters, dispatch }) {
    let mutationToCall = getters.getPage > 1 ? 'increaseItems' : 'setItems';
    let loadingSpinnerToCall =
      getters.getPage > 1 ? 'setLoadingMore' : 'setLoading';

    commit(loadingSpinnerToCall, true);
    
    let options = {
      type: rootGetters['home/getSelectedTabKey'],
      offset: getters.getPage - 1,
      corporationId: rootGetters['user/corporationId'],
      userId: rootGetters['user/userId'],
      activeTripId: rootGetters['user/activeTripId'],
      comingFromEmailML: rootGetters['user/comingFromEmailML'],
      accountNumber: rootGetters['user/accountNumber'],
      hotelIdsCurrentlyShowing:
        rootGetters['hotels/getHotelIdsCurrentlyShowing'],
      ...rootGetters['filter/getSearchQueryOptions']
    };
    if (rootGetters['user/comingFromEmailML']) {
      dispatch('user/setComingFromEmailML', false, {
        root: true
      });
    }

    return suggest({
      apollo: apolloClient.defaultClient,
      ...options
    })
      .then(response => _get(response, 'data.suggest', {}))
      .then(response => {
        let hoteIds = response.map(hotel => hotel.extId);
        commit('setHotelIdsCurrentlyShowing', hoteIds);
        commit(mutationToCall, response);
        commit(loadingSpinnerToCall, false);

        dispatch('filter/setDistanceMinMaxRange', getters.getAllItems, {
          root: true
        });
        let lastPositionNumber =
          getters.getLastPositionNumber == 0
            ? 0
            : getters.getLastPositionNumber;
        response.forEach(elem => {
          lastPositionNumber = lastPositionNumber + 1;
          elem['original_position'] = lastPositionNumber;
        });
        commit('updateLastPostionNumber', lastPositionNumber);
        if (response.length < 1) {
          commit('setAllResultsFetched', true);
        }
        return response;
      })
      .catch(error => {
        commit(loadingSpinnerToCall, false);
        console.log(error);
      });
  },
  resetItems({ commit }) {
    commit('setItems', []);
    commit('resetHotelIdsCurrentlyShowing');
    commit('setAllResultsFetched', false);
    commit('resetPage');
  },
  handleViewMore({ getters, commit, dispatch, rootGetters }) {
    commit('increasePage');
    logMixPanelEvent(
      mixPanelEvent.hotelEvents.viewMoreHotels,
      {
        userId: rootGetters['user/userId'],
        activeTripId: rootGetters['user/activeTripId'],
        distinct_id: rootGetters['user/userId']
      },
      rootGetters['user/getLocationCoordinates']
    );
    if (getters.getShowItemsLength > getters.getItemsToShow.length) {
      dispatch('getCardsContent');
    }
  },
  setNewHotelPrice({ rootGetters, commit }, hotel) {
    let options = {
      hotelIds: hotel.extId.toString(),
      source: hotel.source,
      corporationId: rootGetters['user/corporationId'],
      accountNumber: rootGetters['user/accountNumber'],
      ...rootGetters['filter/getFilterQuery']
    };
    // MAR-1596 If coming from confirmation card checkin and checkout will be there inside hotel data
    if (hotel.checkin && hotel.checkout) {
      options.checkin = hotel.checkin;
      options.checkout = hotel.checkout;
    }
    minimumHotelsPrices({
      apollo: apolloClient.defaultClient,
      ...options
    })
      .then(response => _get(response, 'data.minimumHotelsPrices', {}))
      .then(response => {
        console.log('response', response);
        if (response) {
          const hotelRate = response.hotelMinimumRates[0];
          commit('updateHotelRoomPrices', {
            id: hotel.extId,
            value: {
              price:
                hotelRate.minimumRate > 0
                  ? Math.round(hotelRate.minimumRate)
                  : 'N/A',
              currency: hotelRate.minimumRate > 0 ? hotelRate.currency : '',
              badPrice: hotelRate.badPrice,
              loading: false
            }
          });
          // dispatch(
          //   'filter/setPriceMinMaxRange',
          //   getters.getHotelRoomPricesArray,
          //   {
          //     root: true
          //   }
          // );
        }
      })
      .catch(error => {
        console.warn(error);
        commit('updateHotelRoomPrices', {
          id: hotel.extId,
          value: {
            price: 'N/A',
            currency: '',
            loading: false
          }
        });
        return null;
      });
  },
  async setHotelLabel({ rootGetters, commit }, hotel) {
    let options = {
      hotelInternalId: hotel.extId.toString(),
      hotelName: hotel.name.toString(),
      corporationId: rootGetters['user/corporationId'],
      travelerEmail: rootGetters['user/userEmail'],
      tmc: getContextTMC(),
      hotelChainName: hotel.chainName
    };

    await singleHotelLabeler({
      apollo: apolloClient.defaultClient,
      ...options
    })
      .then(response => _get(response, 'data.singleHotelLabeler', {}))
      .then(response => {
        if (response) {
          commit('updateHotelRoomLabel', {
            hotel: hotel,
            response: response
          });

        }
      })
      .catch(error => {
        console.warn(error);
        commit('updateHotelRoomLabel', {
          id: hotel.extId,
          value: {
            label: '',
            loading: false
          }
        });
        return null;
      });
  },
  setPriceLoader({ commit }, hotelId) {
    commit('updateHotelRoomPrices', {
      id: hotelId,
      value: {
        loading: true
      }
    });
  },
  async setNewHotelPriceUpdated({ rootGetters, commit }, hotel) {
    let options = {
      hotelIds: hotel.extId.toString(),
      source: hotel.source,
      corporationId: rootGetters['user/corporationId'],
      accountNumber: rootGetters['user/accountNumber'],
      checkin: hotel.checkin,
      checkout: hotel.checkout
    };
    try {
      const res = await minimumHotelsPrices({
        apollo: apolloClient.defaultClient,
        ...options
      });
      const hotelRate = res.data.minimumHotelsPrices.hotelMinimumRates[0];
      commit('updateHotelRoomBadPrices', {
        id: hotel.extId,
        value: {
          badPrice: hotelRate.badPrice,
          loading: false
        }
      });
    } catch (error) {
      console.warn(error);
      commit('updateHotelRoomBadPrices', {
        id: hotel.extId,
        value: {
          badPrice: '',
          loading: false
        }
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
