import gql from 'graphql-tag';
import { getContextTMC } from '@/utils/tmc';

const query = gql`
  query availableRoomsInfo(
    $checkin: String!
    $checkout: String!
    $hotelId: String!
    $corporationId: String
    $userId: String!
    $source: SuggestionSourceTypeEnum!
    $accountNumber: String
  ) {
    availableRoomsInfo(
      checkin: $checkin
      checkout: $checkout
      hotelId: $hotelId
      corporationId: $corporationId
      source: $source
      userId: $userId
      accountNumber: $accountNumber
      tmc: "${getContextTMC()}"
    ) {
      availableRoomsInfo
      currency
      currencyName
    }
  }
`;

export default function availableRoomsInfo({
  apollo,
  checkin,
  checkout,
  hotelId,
  corporationId,
  userId,
  source,
  accountNumber
}) {
  return apollo.query({
    query,
    variables: {
      checkin,
      checkout,
      hotelId,
      corporationId,
      userId,
      source,
      accountNumber,
      getContextTMC
    }
  });
}
