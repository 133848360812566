<template>
  <div class="price mt-4 mb-4">
    <div v-if="total && taxInclusive" class="price__total">
      <div class="price__label">
        <strong>{{ currency + Math.round(price).toLocaleString() }}</strong> / night
      </div>
      <tax-tool-tip></tax-tool-tip>
      <strong>{{ currency + total.toLocaleString() }}</strong> total
    </div>
    <div v-else-if="total && !taxInclusive" class="price__total">
      <div class="price__label fs-22">
        <del v-if="badPrice" class="cancelled-price fw-600 mr-2">{{
            currency + badPrice
          }}</del>
        <strong class="fw-800 text-black">{{ currency + Math.round(price).toLocaleString() }}</strong>
        <span class="nunito-font-family text-black fs-12"> / per night*</span>
      </div>

      <div>
        <div>
          <strong class="price__tax-label mr-1">{{
            currency + total.toFixed(2)
          }}</strong>
          <strong class="price__tax-label nunito-font-family fs-12">before tax</strong>
        </div>
      </div>
      <div>
        <tax-tool-tip></tax-tool-tip>
        <strong>{{ currency + (total + taxPrice).toFixed(2) }}</strong> total
        <br />
        <div class="price__tax-label nunito-font-family fs-12">
          <strong>Including Tax</strong>
        </div>
      </div>
    </div>
    <div v-else>
      <strong class="active-price">{{
        currency + Math.round(price).toLocaleString()
      }}</strong>
      <del v-if="badPrice" class="cancelled-price">{{
        currency + badPrice
      }}</del>
      <span class="nunito-font-family fs-12"> / per night*</span>
    </div>
  </div>
</template>

<script>
import TaxToolTip from './TaxToolTip';
export default {
  name: 'Price',
  components: {
    TaxToolTip
  },
  props: {
    price: {
      type: [String, Number],
      required: true
    },
    badPrice: {
      default: '',
      type: [String, Number],
      required: true
    },
    currency: {
      type: [String],
      required: true
    },
    total: {
      type: [String, Number]
    },
    label: {
      type: [String, null]
    },
    taxInclusive: {
      type: Boolean,
      required: false
    },
    taxPrice: {
      type: [String, Number],
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/__utils';
.price {
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  white-space: nowrap;
  strong {
    font-weight: 800;
  }
  &__total {
    font-size: 24px;
    line-height: 28px;
  }
  &__label {
    color: $light;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 2px;
    font-weight: 600;
  }
  &__tax-label {
    color: $light;
    font-size: 10px;
    line-height: 19px;
    margin-bottom: 2px;
    font-weight: 600;
  }

  .active-price {
    padding-right: 10px;
  }

  .cancelled-price {
    color: #D91313;
  }
}
</style>
