import gql from 'graphql-tag';
import { getContextTMC } from '@/utils/tmc';

const mutation = gql`
  mutation resendVerificationCode($uId: String!) {
    resendVerificationCode(input: { uId: $uId, client: "${getContextTMC()}" }) {
      user {
        id
        userId
        firstName
        lastName
        email
        authenticationToken
        userStatus
      }
      errors
      clientMutationId
      success
    }
  }
`;

export default function resendVerificationCode({ apollo, uId }) {
  return apollo.mutate({
    mutation,
    variables: {
      uId,
      getContextTMC
    }
  });
}
