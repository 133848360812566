import Vue from 'vue';
import apolloClient from '@/vue-apollo';
import _get from 'lodash/get';
import fetchHotelBrands from '@/queries/fetchHotelBrands';
import { getDecryptedValue, storeEncryptedValue } from '@/utils/crypto';

const state = {
  brands: null
};

const getters = {
  getBrands: state => {
    return getDecryptedValue(state.brands || Vue.localStorage.get('HOTEL_BRANDS'));
  }
};

const mutations = {
  updateHotelBrands(state, payload) {
    state.brands = storeEncryptedValue('HOTEL_BRANDS', payload);
  }
};

const actions = {
  async setHotelBrands({ getters, commit }) {
    if (getters.getBrands) {
      return;
    }

    fetchHotelBrands({
      apollo: apolloClient.defaultClient
    })
      .then(response => _get(response, 'data.hotelFilterList', {}))
      .then(response => {
        commit('updateHotelBrands', response);
      });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
