import gql from 'graphql-tag';
import { getContextTMC } from '@/utils/tmc';

const query = gql`
  query tmcTaxInclusive($tmc: String!) {
    tmcTaxInclusive(tmc: $tmc)
  }
`;

export default function tmcTaxInclusive({ apollo }) {
  const tmc = getContextTMC();
  return apollo.query({
    query,
    variables: {
      tmc: tmc
    }
  });
}
