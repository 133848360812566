import gql from 'graphql-tag';
import { getContextTMC } from '@/utils/tmc';

const query = gql`
  query singleHotelLabeler(
    $hotelInternalId: String!
    $hotelName: String!
    $corporationId: String!
    $travelerEmail: String!
    $tmc: String!
  ) {
    singleHotelLabeler(
      hotelInternalId: $hotelInternalId
      hotelName: $hotelName
      travelerEmail: $travelerEmail
      corporationId: $corporationId
      tmc: $tmc
    )
  }
`;

export default function singleHotelLabeler({
  apollo,
  hotelInternalId,
  hotelName,
  corporationId,
  travelerEmail,
}) {
  let tmc = getContextTMC();
  return apollo.query({
    query,
    variables: {
      hotelInternalId,
      hotelName,
      corporationId,
      travelerEmail,
      tmc
    }
  });
}
