import gql from 'graphql-tag';
import { getContextTMC } from '@/utils/tmc';

const query = gql`
  query fetchHotelFacilities(
    $hotelId: String
    $source: SuggestionSourceTypeEnum!
  ) {
    fetchHotelFacilities(hotelExtId: $hotelId, source: $source, tmc: "${getContextTMC()}") {
      hotelId
      facilities
    }
  }
`;

export default function fetchHotelFacilities({ apollo, hotelId, source }) {
  return apollo.query({
    query,
    variables: {
      hotelId,
      source,
      getContextTMC
    }
  });
}
