import gql from 'graphql-tag';

// FIXME!! Receive and send the travelManagement company received in URL
const query = gql`
  query fetchTmcByTripId($tripId: String!) {
    tmcByTripId(tripId: $tripId)
  }
`;

// tripId should be a dictionary with uId and tripId as keys e.g: {uId: "123", tripId: "234"}
export default function fetchTmcByTripId({ apollo, tripId }) {
  return apollo.query({
    query,
    variables: {tripId: tripId}
  });
}
