import gql from 'graphql-tag';

const query = gql`
  query {
    hotelFilterList
  }
`;

export default function fetchHotelBrands({ apollo }) {
  return apollo.query({
    query,
    variables: {}
  });
}
